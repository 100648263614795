<template>
    <modal ref="modalRealizarSoporte" titulo="Realizar un soporte" adicional="Realizar" @adicional="enviarSoporte">
        <ValidationObserver ref="form">
            <div class="row mx-0 j-center">
                <div class="col-8 my-3">
                    <p class="text-general pl-3 f-12">
                        Cuéntanos la situación
                    </p>
                    <ValidationProvider v-slot="{ errors }" rules="max:250|required" name="Justificación">
                        <el-input v-model="justificacion" type="textarea" :rows="7" placeholder="¿Qué problema has tenido?" class="w-100 br-12" />
                        <span class="text-danger f-10">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="col-8 mt-3">
                    <p class="text-general pl-3 f-12 mb-1">
                        Adjuntar algunas evidencias
                    </p>
                    <div class="row mx-0 d-flex justify-content-around">
                        <slim-cropper ref="fotoReclamo1" :options="slimOptions" class="border cr-pointer" style="height:120px;width:120px;background:#F8F9FF;border-radius:4px;" />
                        <slim-cropper ref="fotoReclamo2" :options="slimOptions" class="border cr-pointer" style="height:120px;width:120px;background:#F8F9FF;border-radius:4px;" />
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '@/services/pedidos/pedidos'

export default {
    data(){
        return {
            justificacion: '',
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            idPedido: null,
        }
    },
    methods: {
        toggle(pedido){
            this.limpiar();
            this.idPedido = pedido;
            this.$refs.modalRealizarSoporte.toggle();
        },

        async enviarSoporte(){
            try {

                let validate = await this.$refs.form.validate()
                if(!validate) return;

                let params = {
                    idPedido: this.idPedido,
                    justificacion: this.justificacion,
                    estado: 33,
                    evidencias: [
                        this.$refs.fotoReclamo1.instanciaCrop.dataBase64.output.image,
                        this.$refs.fotoReclamo2.instanciaCrop.dataBase64.output.image
                    ].filter(el=>el)
                }
                const { data } = await Service.postSoporte(params)
                
                this.$emit('update')
                this.notificacion('Mensaje', 'Se ha iniciado el soporte','success')
                this.$refs.modalRealizarSoporte.toggle();
            } catch (error){
                this.errorCatch(error)
            } finally{
                this.cargando = false
            }
        },
        limpiar(){
            this.justificacion = null;
            this.$refs.fotoReclamo1.instanciaCrop.remove();
            this.$refs.fotoReclamo2.instanciaCrop.remove();
        },
    }
}
</script>
